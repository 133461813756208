import {
  createEntityAdapter,
  createSlice,
  EntityState,
  PayloadAction,
} from '@reduxjs/toolkit';

import { blog as types } from '@codesass/types';

export type BlogState = EntityState<types.ArticleListItem> & {
  item?: types.ArticleDetails;
  paging?: types.Pagination;
};

export const blogAdapter = createEntityAdapter<types.ArticleListItem>({
  selectId: article => article.slug,
});

const initialState = blogAdapter.getInitialState<BlogState>({
  ids: [],
  entities: {},
});

const blogSlice = createSlice({
  name: 'blog',
  initialState,
  reducers: {
    addArticles: blogAdapter.upsertMany,
    addArticle: blogAdapter.upsertOne,
    setPaging(state, action: PayloadAction<types.Pagination>) {
      state.paging = action.payload;
    },
    setItem(state, action: PayloadAction<types.ArticleDetails>) {
      state.item = action.payload;
    },
  },
});

export default blogSlice.reducer;

export const { addArticles, addArticle, setPaging, setItem } =
  blogSlice.actions;
