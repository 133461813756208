import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';

import {
  MouseEventHandler,
  MouseEvent,
  ReactNode,
  useCallback,
  useMemo,
} from 'react';

import { useDispatch } from 'react-redux';

import { showSidebar } from '../slice';

type AutoHideListItemButtonProps = {
  side: 'left' | 'right';
  icon?: ReactNode;
  title: string;
  selected?: string[] | boolean;
} & ({ path: string } | { onClick: MouseEventHandler<HTMLDivElement> });

const AutoHideListItemButton = (props: AutoHideListItemButtonProps) => {
  const { side, icon, title, selected } = props;
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const hasPath = 'path' in props;
  const isSelected = useMemo(() => {
    if (typeof selected === 'boolean') return selected;
    if (selected) return selected.includes(pathname);
    // eslint-disable-next-line unicorn/consistent-destructuring
    if (hasPath) return pathname === props.path;

    return false;
  }, [hasPath, pathname, props, selected]);

  const navigateTo = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      if (matches) dispatch(showSidebar({ [side]: false }));
      // eslint-disable-next-line unicorn/consistent-destructuring
      if (hasPath) navigate(props.path);
      else props.onClick(e);
    },
    [dispatch, hasPath, matches, props, side]
  );

  return (
    <ListItemButton onClick={navigateTo} selected={isSelected}>
      {icon && <ListItemIcon>{icon}</ListItemIcon>}
      <ListItemText primary={title}></ListItemText>
    </ListItemButton>
  );
};

export default AutoHideListItemButton;
