import { AppBar, Box, IconButton, Toolbar, Typography } from '@mui/material';
import { Facebook, Email } from '@mui/icons-material';

import { StaticImage } from 'gatsby-plugin-image';

import { useSelector, useDispatch } from 'react-redux';

import { useCallback } from 'react';

import Link from 'modules/ui/components/Link';
import { selectIsFooterShown } from 'modules/ui/selectors';
import { showConsent } from 'modules/ui/slice';

const Footer = () => {
  const dispatch = useDispatch();
  const isFooterShown = useSelector(selectIsFooterShown);

  const showConsentDialog = useCallback(() => {
    dispatch(showConsent(true));
  }, [dispatch]);

  if (!isFooterShown) return null;

  return (
    <AppBar
      component="footer"
      sx={{
        p: 2,
        bgcolor: 'black',
        color: 'whitesmoke',
        zIndex: ({ zIndex: { drawer } }) => drawer + 1,
        position: 'static',
        bottom: 0,
        top: 'auto',
      }}
    >
      <Toolbar
        sx={{
          flexDirection: { xs: 'column', md: 'row' },
        }}
      >
        <Typography variant="h6" component="h1">
          <Link
            to="/"
            color="inherit"
            variant="button"
            underline="none"
            display="flex"
            alignItems="center"
          >
            <StaticImage
              src="../../../../../assets/images/logo.png"
              alt="CODE SASS"
              placeholder="blurred"
              layout="fixed"
              width={50}
              height={50}
              aria-hidden="true"
            />
            <Box sx={{ ml: 2, textDecoration: 'uppercase' }}>CODE SASS</Box>
          </Link>
        </Typography>
        <Box
          sx={{
            flex: { md: 1 },
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            alignItems: 'center',
          }}
        >
          <Link
            showActive={false}
            to="/courses"
            variant="button"
            underline="none"
            sx={{ ml: 2 }}
          >
            คอร์ส
          </Link>
          <Link
            showActive={false}
            to="/blog"
            variant="button"
            underline="none"
            sx={{ ml: 2 }}
          >
            บทความ
          </Link>
          <Link
            onClick={showConsentDialog}
            color="inherit"
            variant="button"
            underline="none"
            sx={{ ml: 2, cursor: 'pointer' }}
          >
            แก้ไขการเก็บข้อมูล
          </Link>
          <Link
            showActive={false}
            to="/privacy"
            variant="button"
            underline="none"
            sx={{ ml: 2 }}
          >
            นโยบายข้อมูลบุคคล
          </Link>
          <Link
            showActive={false}
            to="/policy"
            variant="button"
            underline="none"
            sx={{ ml: 2 }}
          >
            ข้อตกลงการใช้งาน
          </Link>
          <Toolbar sx={{ ml: 'auto' }}>
            <IconButton
              size="large"
              color="inherit"
              component="a"
              href="mailto:codesass@hotmail.com"
            >
              <Email />
            </IconButton>
            <IconButton
              size="large"
              color="inherit"
              component="a"
              href="https://www.facebook.com/codesass"
            >
              <Facebook />
            </IconButton>
          </Toolbar>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Footer;
