import { courses as types } from '@codesass/types';

import TocLesson from './TocLesson';

type TocLessonListProps = {
  lessons: types.Toc[number]['lessons'];
  courseSlug: types.Course['slug'];
};

const TocLessonList = ({ lessons, courseSlug }: TocLessonListProps) => {
  return (
    <>
      {lessons.map(lesson => (
        <TocLesson
          key={lesson.slug}
          courseSlug={courseSlug}
          {...lesson}
        ></TocLesson>
      ))}
    </>
  );
};

export default TocLessonList;
