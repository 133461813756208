import { globalHistory } from '@reach/router';

import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import config, { defaultFooterConfig } from 'modules/ui/config';

import { showFooter } from '../slice';

const findPathEntry = (pathname: string) => {
  const footerConfig = config.footer?.find(
    ({ path }) => path && new RegExp(path).test(pathname)
  );

  return {
    ...defaultFooterConfig,
    ...footerConfig,
  };
};

const useHandleFooter = (): void => {
  const dispatch = useDispatch();

  const configFooter = useCallback(
    (pathname: string) => {
      dispatch(showFooter(findPathEntry(pathname)?.shown));
    },
    [dispatch]
  );

  useEffect(() => {
    return globalHistory.listen(({ location: { pathname } }) => {
      configFooter(pathname);
    });
  }, [configFooter, dispatch]);

  useEffect(() => {
    configFooter(globalHistory.location.pathname);
  }, [configFooter]);
};

export default useHandleFooter;
