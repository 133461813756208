import { forwardRef } from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { Button as MuiButton, ButtonProps } from '@mui/material';

const ButtonLink = forwardRef<HTMLButtonElement, ButtonProps & { to?: string }>(
  (props, ref) => {
    const { to, ...buttonProps } = props;
    const component = to ? GatsbyLink : `button`;

    return (
      <MuiButton component={component} ref={ref} to={to} {...buttonProps} />
    );
  }
);

export default ButtonLink;
