import { styled } from '@mui/material/styles';

import { ui as types } from '@codesass/types';

import LinkItem from './LinkItem';
import TableOfContentsItems from './TableOfContentsItems';

type NestedLinkItemsProps = Required<types.TableOfContentsItem>;

const TocWrapper = styled('div')(({ theme }) => ({
  '& .MuiListItemText-root': {
    paddingLeft: theme.spacing(1.5),
  },
}));

const NestedLinkItems = ({ title, url, items }: NestedLinkItemsProps) => {
  return (
    <>
      <LinkItem title={title} url={url}></LinkItem>
      <TocWrapper>
        <TableOfContentsItems items={items}></TableOfContentsItems>
      </TocWrapper>
    </>
  );
};

export default NestedLinkItems;
