import { RootState } from 'modules/rootReducer';

import { State } from './slice';

export const selectSidebar = (state: RootState): State['sidebar'] => {
  return state.ui.sidebar;
};

export const selectIsFooterShown = (
  state: RootState
): State['isFooterShown'] => {
  return state.ui.isFooterShown;
};

export const selectIsConsentShown = (
  state: RootState
): State['isConsentShown'] => {
  return state.ui.isConsentShown;
};

export const selectFlashMessage = (state: RootState): State['flashMessage'] => {
  return state.ui.flashMessage;
};

export const selectIsLoading = (state: RootState): State['isLoading'] => {
  return state.ui.isLoading;
};

export const selectCurrentTocId = (state: RootState): State['currentTocId'] => {
  return state.ui.currentTocId;
};

export const selectIsTopBarHide = (state: RootState): State['isTopbarHide'] => {
  return state.ui.isTopbarHide;
};

export const selectAcceptedConsent = (
  state: RootState
): State['acceptedConsent'] => {
  return state.ui.acceptedConsent;
};
