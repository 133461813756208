import { courses as types } from '@codesass/types';
import { courses } from '@codesass/api';
import { useCallback, useMemo } from 'react';
import {
  OndemandVideo,
  FactCheck,
  RadioButtonUnchecked,
  CheckCircle,
  WorkspacePremium,
} from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import {
  ListItemButton,
  ListItemIcon,
  Box,
  ListItemText,
  useMediaQuery,
} from '@mui/material';
import { Link, navigate } from 'gatsby';

import { useSelector, useDispatch } from 'react-redux';

import { useLocation } from '@reach/router';

import { getLessonPath } from 'modules/courses/helpers/paths';
import { RootState } from 'modules/rootReducer';
import { showSidebar } from 'modules/ui/slice';

type TocLessonProps = {
  courseSlug: types.Course['slug'];
} & types.Toc[number]['lessons'][number];

const TocLesson = ({ courseSlug, slug, type, title }: TocLessonProps) => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const lessonPath = getLessonPath(courseSlug, slug);
  const { viewStatuses } = useSelector((state: RootState) => state.courses);
  const isCurrentLesson = pathname.startsWith(lessonPath);

  const Icon = {
    video: OndemandVideo,
    exam: FactCheck,
    certificationExam: WorkspacePremium,
  }[type];

  const status = viewStatuses?.[slug]?.status;
  const statusColor =
    !isCurrentLesson && status === courses.StatusMapping.Done
      ? grey[400]
      : 'inherit';

  const StatusIcon = useMemo(() => {
    if (status === undefined) return null;

    return {
      [courses.StatusMapping['Viewing']]: RadioButtonUnchecked,
      [courses.StatusMapping['Done']]: CheckCircle,
    }[status.toString()];
  }, [status]);

  const navigateToLesson = useCallback(() => {
    if (matches) dispatch(showSidebar({ left: false }));
    navigate(lessonPath);
  }, [dispatch, lessonPath, matches]);

  return (
    <ListItemButton
      key={slug}
      onClick={navigateToLesson}
      sx={{
        backgroundColor: isCurrentLesson ? 'primary.light' : 'inherit',
        ':hover': {
          backgroundColor: 'primary.main',
          color: 'inherit',
        },
        color: statusColor,
      }}
    >
      <ListItemIcon sx={{ color: 'inherit' }}>
        <Box position="relative">
          <Icon></Icon>
          {StatusIcon && (
            <StatusIcon
              sx={{
                fontSize: 10,
              }}
            ></StatusIcon>
          )}
        </Box>
      </ListItemIcon>
      <ListItemText primary={title}></ListItemText>
    </ListItemButton>
  );
};

export default TocLesson;
