import { shared } from '@codesass/api';
import { LocationProvider } from '@reach/router';
import { Provider } from 'react-redux';

import { WrapPageElementNodeArgs } from 'gatsby';

import store from 'modules/store';

shared.init();

const wrapPageElement = ({ element }: WrapPageElementNodeArgs) => {
  return (
    <Provider store={store}>
      <LocationProvider>{element}</LocationProvider>
    </Provider>
  );
};

export default wrapPageElement;
