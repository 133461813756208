import { courses as types } from '@codesass/types';
import { CircularProgress, List, ListItemButton } from '@mui/material';

import { styled } from '@mui/material/styles';

import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Link } from 'gatsby';

import { useLocation } from '@reach/router';

import { loadToc, loadLessonViewStatuses } from 'modules/courses/slice';
import { RootState } from 'modules/rootReducer';

import { getCourseOverviewPath } from 'modules/courses/helpers/paths';

import TocUnitList from './TocUnitList';

type TocProps = {
  slug: types.Course['slug'];
};

const Wrapper = styled('div')({
  '& .MuiList-root': {
    padding: 0,
  },
});

const Toc = ({ slug }: TocProps) => {
  const dispatch = useDispatch();
  const { currentToc: toc } = useSelector((state: RootState) => state.courses);
  const { pathname } = useLocation();
  const overviewPath = getCourseOverviewPath(slug);

  useEffect(() => {
    dispatch(loadToc(slug));
    dispatch(loadLessonViewStatuses(slug));
  }, [dispatch, slug]);

  if (!toc) {
    return (
      <CircularProgress
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      />
    );
  }

  return (
    <Wrapper>
      <List>
        <ListItemButton
          component={Link}
          to={overviewPath}
          sx={{
            display: 'block',
            textAlign: 'center',
            backgroundColor:
              pathname === overviewPath ? 'primary.light' : 'inherit',
            ':hover': {
              backgroundColor: 'primary.main',
            },
            p: 2,
          }}
        >
          รายละเอียดคอร์ส
        </ListItemButton>
      </List>
      <TocUnitList courseSlug={slug} units={toc}></TocUnitList>
    </Wrapper>
  );
};

export default Toc;
