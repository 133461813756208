import { combineReducers } from '@reduxjs/toolkit';

import auth from './auth/slice';
import blog from './blog/slice';
import courses from './courses/slice';
import ui from './ui/slice';

const rootReducer = combineReducers({
  auth,
  blog,
  ui,
  courses,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
