import { Menu, OndemandVideo, Article } from '@mui/icons-material';
import {
  AppBar,
  Box,
  IconButton,
  Toolbar,
  Typography,
  List,
  ListSubheader,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { StaticImage } from 'gatsby-plugin-image';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ui as types } from '@codesass/types';

import Link from 'modules/ui/components/Link';

import { HideOnScroll, RightSidebar } from 'modules/ui/components/shared';
import { selectSidebar } from 'modules/ui/selectors';
import * as actions from 'modules/ui/slice';

import AutoHideListItemButton from '../../AutoHideListItemButton';

import UserMenu from './UserMenu';

const HomePageLink = styled(Link)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',

  '& > * + *': {
    marginLeft: theme.spacing(1),
  },
}));

const NavBar = () => {
  const dispatch = useDispatch();
  const { enableLeft, enableRight } = useSelector(selectSidebar);

  const toggleSidebar = useCallback(
    (side: types.SidebarSide) => () => {
      dispatch(actions.toggleSidebar(side));
      dispatch(
        actions.showSidebar({ [side === 'left' ? 'right' : 'left']: false })
      );
    },
    [dispatch]
  );

  const setIsTopbarHide = useCallback(
    (trigger: boolean) => {
      dispatch(actions.setIsTopbarHide(!trigger));
    },
    [dispatch]
  );

  return (
    <HideOnScroll direction="down" onTrigger={setIsTopbarHide}>
      <AppBar
        sx={{
          bgcolor: 'white',
          color: 'grey.800',
          zIndex: theme => theme.zIndex.drawer + 1,
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={toggleSidebar('left')}
            size="large"
            sx={{ visibility: enableLeft ? 'visible' : 'hidden' }}
          >
            <Menu />
          </IconButton>
          <Typography
            variant="h6"
            component="h1"
            sx={{ display: { xs: 'none', md: 'block' } }}
          >
            <HomePageLink
              to="/"
              color="inherit"
              variant="button"
              underline="none"
            >
              <StaticImage
                src="../../../../../assets/images/logo.png"
                alt="CODE SASS"
                placeholder="blurred"
                layout="fixed"
                width={40}
                height={40}
                aria-hidden="true"
              />
              <Box sx={{ textDecoration: 'uppercase' }}>CODE SASS</Box>
            </HomePageLink>
          </Typography>
          <Box
            sx={{
              flex: { md: 1 },
              display: { xs: 'none', md: 'flex' },
              alignItems: { md: 'center' },
            }}
          >
            <Link
              to="/courses"
              variant="button"
              underline="none"
              sx={{ ml: 2 }}
            >
              คอร์สออนไลน์
            </Link>
            <Link to="/blog" variant="button" underline="none" sx={{ ml: 2 }}>
              บทความ
            </Link>
            <UserMenu></UserMenu>
          </Box>
          <HomePageLink
            to="/"
            color="inherit"
            variant="button"
            underline="none"
            sx={{ display: { xs: 'block', md: 'none' }, mx: 'auto' }}
          >
            <StaticImage
              src="../../../../../assets/images/logo.png"
              alt="CODE SASS"
              placeholder="blurred"
              layout="fixed"
              width={50}
              height={50}
              aria-hidden="true"
            />
          </HomePageLink>
          {enableRight && (
            <>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={toggleSidebar('right')}
                size="large"
                sx={{ display: { xs: 'block', md: 'none' } }}
              >
                <Menu />
              </IconButton>
              <RightSidebar.RendererSource>
                <List
                  subheader={
                    <ListSubheader
                      component="div"
                      sx={{
                        fontSize: 20,
                        textAlign: 'center',
                        bgcolor: 'primary.main',
                      }}
                    >
                      เมนู
                    </ListSubheader>
                  }
                  sx={{ p: 0 }}
                >
                  <AutoHideListItemButton
                    icon={<OndemandVideo></OndemandVideo>}
                    title="คอร์สออนไลน์"
                    path="/courses"
                    side="right"
                  ></AutoHideListItemButton>
                  <AutoHideListItemButton
                    icon={<Article></Article>}
                    title="บทความ"
                    path="/blog"
                    side="right"
                  ></AutoHideListItemButton>
                  <UserMenu></UserMenu>
                </List>
              </RightSidebar.RendererSource>
            </>
          )}
        </Toolbar>
      </AppBar>
    </HideOnScroll>
  );
};

export default NavBar;
