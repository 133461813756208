import { RootState } from 'modules/rootReducer';

export const selectProfile = (state: RootState) => state.auth.profile;

export const selectIsLoggedIn = (state: RootState) =>
  Boolean(selectProfile(state));

export const selectProfileLoaded = (state: RootState) =>
  state.auth.isProfileLoaded;

export const selectEmailVerified = (state: RootState) =>
  state.auth.profile?.emailVerified;

export const selectIsAdmin = (state: RootState) =>
  state.auth.profile?.email === process.env['GATSBY_ADMIN_EMAIL'];
