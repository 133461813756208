import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

import { ui as types } from '@codesass/types';

export type State = {
  sidebar: {
    enableLeft: boolean;
    enableRight: boolean;
    isLeftShown: boolean;
    isRightShown: boolean;
  };
  flashMessage?: types.FlashMessage | undefined;
  currentTocId?: string | undefined;
  isLoading: boolean;
  isTopbarHide: boolean;
  isFooterShown: boolean;
  isConsentShown: boolean;
  acceptedConsent: boolean;
};

type SidebarSidePayloadAction = {
  [Side in types.SidebarSide]?: boolean;
};

const initialState: State = {
  sidebar: {
    enableLeft: false,
    enableRight: false,
    isLeftShown: false,
    isRightShown: false,
  },
  isLoading: false,
  isTopbarHide: false,
  isFooterShown: true,
  isConsentShown: false,
  acceptedConsent: true,
};

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    enableSidebar(state, action: PayloadAction<SidebarSidePayloadAction>) {
      const sidebar = state.sidebar;
      const { left, right } = action.payload;

      sidebar.enableLeft = Boolean(left);
      sidebar.enableRight = Boolean(right);
    },
    toggleSidebar(state, action: PayloadAction<types.SidebarSide>) {
      const sidebar = state.sidebar;

      action.payload === 'left'
        ? (sidebar.isLeftShown = !sidebar.isLeftShown)
        : (sidebar.isRightShown = !sidebar.isRightShown);
    },
    showSidebar(state, action: PayloadAction<SidebarSidePayloadAction>) {
      const sidebar = state.sidebar;
      const { left, right } = action.payload;

      if (left !== undefined) sidebar.isLeftShown = Boolean(left);
      if (right !== undefined) sidebar.isRightShown = Boolean(right);
    },
    showFlashMessage(state, action: PayloadAction<types.FlashMessage>) {
      state.flashMessage = action.payload;
    },
    clearFlashMessage(state) {
      state.flashMessage = undefined;
    },
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setCurrentTocId(state, action: PayloadAction<string>) {
      state.currentTocId = `#${action.payload}`;
    },
    clearCurrentTocId(state) {
      state.currentTocId = undefined;
    },
    setIsTopbarHide(state, action: PayloadAction<boolean>) {
      state.isTopbarHide = action.payload;
    },
    showFooter(state, action: PayloadAction<boolean>) {
      state.isFooterShown = action.payload;
    },
    showConsent(state, action: PayloadAction<boolean>) {
      state.isConsentShown = action.payload;
    },
    setAcceptedConsent(state, action: PayloadAction<boolean>) {
      state.acceptedConsent = action.payload;
    },
  },
});

export default uiSlice.reducer;

export const {
  enableSidebar,
  toggleSidebar,
  showSidebar,
  showFlashMessage,
  clearFlashMessage,
  setIsLoading,
  setCurrentTocId,
  clearCurrentTocId,
  setIsTopbarHide,
  showFooter,
  showConsent,
  setAcceptedConsent,
} = uiSlice.actions;
