import { courses as types } from '@codesass/types';

export const getCoursePath = (slug: types.Course['slug']) => `/courses/${slug}`;

export const getCourseOverviewPath = (slug: types.Course['slug']) =>
  `/courses/${slug}/overview`;

export const getLessonPath = (
  courseSlug: types.Course['slug'],
  lessonSlug: types.Lesson['slug']
) => `/courses/${courseSlug}/lessons/${lessonSlug}`;

export const getCourseRegisterPath = (slug: types.Course['slug']) =>
  `/courses/${slug}/register`;
