import { Alert, Box, Snackbar, Toolbar, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ReactNode, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PuffLoader } from 'react-spinners';

import { RootState } from 'modules/rootReducer';
import { useHandleSidebar, useHandleFooter } from 'modules/ui/hooks';
import {
  selectFlashMessage,
  selectIsLoading,
  selectSidebar,
} from 'modules/ui/selectors';
import { clearFlashMessage } from 'modules/ui/slice';

import { loadProfile } from 'modules/auth/slice';

import { Sidebar } from '../shared';
import { appbarHeight, footerHeight } from '../../constants';

import ConsentNotification from '../ConsentNotification';

import ConsentDialog from '../ConsentDialog';

import { NavBar } from './shared';
import Footer from './shared/Footer';

type NormalLayoutProps = {
  children: ReactNode;
};

const NormalLayout = ({ children }: NormalLayoutProps) => {
  const dispatch = useDispatch();
  const flashMessage = useSelector((state: RootState) =>
    selectFlashMessage(state)
  );
  // const isLoading = useSelector((state: RootState) => selectIsLoading(state));
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const screenSize = isSmallScreen ? 'xs' : 'md';
  const minHeight = `calc(100vh - ${appbarHeight[screenSize]}px - ${footerHeight[screenSize]}px)`;
  const { isLeftShown, isRightShown } = useSelector(selectSidebar);
  const showMain = isSmallScreen && (isLeftShown || isRightShown);

  const closeFlashMessage = useCallback(() => {
    dispatch(clearFlashMessage());
  }, [dispatch]);

  useHandleSidebar();
  useHandleFooter();

  useEffect(() => {
    dispatch(loadProfile());
  }, [dispatch]);

  return (
    <>
      <NavBar></NavBar>
      <Toolbar />
      <Box display="flex">
        <Sidebar />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            width: '100%',
            display: showMain ? 'none' : 'block',
          }}
        >
          <Box minHeight={minHeight}>{children}</Box>
          <Footer></Footer>
          <ConsentNotification></ConsentNotification>
          <ConsentDialog></ConsentDialog>
        </Box>
        {/* {isLoading && (
          <Box
            sx={{
              position: 'fixed',
              top: '50%',
              left: '50%',
              transform: 'transition(-50%, -50%)',
            }}
          >
            <PuffLoader loading={true} color="red"></PuffLoader>
          </Box>
        )} */}
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={Boolean(flashMessage)}
          onClose={closeFlashMessage}
        >
          <Alert
            onClose={closeFlashMessage}
            severity={flashMessage?.type || 'info'}
          >
            {flashMessage?.message}
          </Alert>
        </Snackbar>
      </Box>
    </>
  );
};

export default NormalLayout;
