import Cookies from 'js-cookie';

export type ConsentDialogSetting = {
  analysis: boolean;
  marketing: boolean;
};

export const storeConsent = ({ analysis, marketing }: ConsentDialogSetting) => {
  Cookies.set('gatsby-gdpr-google-tagmanager', analysis.toString());
  Cookies.set('gatsby-gdpr-facebook-pixel', marketing.toString());
  Cookies.set('accept-consent', 'true');
};

export const loadConsent = (): ConsentDialogSetting => {
  return {
    analysis: Cookies.get('gatsby-gdpr-google-tagmanager') === 'true',
    marketing: Cookies.get('gatsby-gdpr-facebook-pixel') === 'true',
  };
};
