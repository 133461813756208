import Highlight, { defaultProps } from 'prism-react-renderer';
import theme from 'prism-react-renderer/themes/vsDark';
import { ReactNode } from 'react';
import { Box } from '@mui/material';
// @ts-ignore
import { preToCodeBlock } from 'mdx-utils';

export type CodeHighlighterProps = {
  children: ReactNode;
};

const CodeHighlighter = (preProps: CodeHighlighterProps) => {
  const props = preToCodeBlock(preProps);

  if (!props)
    return (
      <Box
        component="pre"
        sx={{ '& code': { bgcolor: 'white', p: 0 } }}
        {...preProps}
      />
    );

  const { codeString, language } = props;

  if (language === '')
    return (
      <Box
        component="pre"
        sx={{ '& code': { bgcolor: 'white', p: 0 } }}
        {...preProps}
      />
    );

  return (
    <Highlight
      {...defaultProps}
      theme={theme}
      code={codeString}
      language={language}
    >
      {({ className, style, tokens, getLineProps, getTokenProps }) => (
        <pre className={className} style={style}>
          <Box p={2}>
            {tokens.map((line, i) => (
              <div {...getLineProps({ line, key: i })}>
                {line.map((token, key) => (
                  <span {...getTokenProps({ token, key })} />
                ))}
              </div>
            ))}
          </Box>
        </pre>
      )}
    </Highlight>
  );
};

export default CodeHighlighter;
