import {
  Tooltip,
  IconButton,
  Avatar,
  Menu,
  MenuItem,
  CircularProgress,
  Divider,
  useMediaQuery,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  List,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useState, MouseEvent, useCallback, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ExitToApp,
  School,
  ManageAccounts,
  SupervisorAccount,
  ExpandLess,
  ExpandMore,
  Login,
} from '@mui/icons-material';
import { navigate, Link } from 'gatsby';

import {
  selectIsAdmin,
  selectProfile,
  selectProfileLoaded,
} from 'modules/auth/selectors';
import ButtonLink from 'modules/ui/components/ButtonLink';
import * as actions from 'modules/auth/slice';
import { showSidebar } from 'modules/ui/slice';

import AutoHideListItemButton from '../../AutoHideListItemButton';

const UserMenu = () => {
  const dispatch = useDispatch();
  const profile = useSelector(selectProfile);
  const isAdmin = useSelector(selectIsAdmin);
  const isProfileLoaded = useSelector(selectProfileLoaded);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isDesktopOpen = Boolean(anchorEl);
  const [isMobileOpen, setIsMobileOpen] = useState(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  const handleDesktopMenuClick = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    },
    []
  );

  const handleMobileMenuClick = useCallback(() => {
    setIsMobileOpen(status => !status);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const logout = useCallback(() => {
    dispatch(actions.logout());
  }, [dispatch]);

  const navigateTo = useCallback(
    (to: string) => () => {
      dispatch(showSidebar({ right: false }));
      navigate(to);
    },
    [dispatch]
  );

  const desktopMenu = useMemo(() => {
    return profile ? (
      <>
        <Tooltip title="เมนูจัดการบัญชีผู้ใช้งาน">
          <IconButton
            size="small"
            color="inherit"
            sx={{ ml: 'auto' }}
            onClick={handleDesktopMenuClick}
          >
            {profile.avatar ? (
              <Avatar src={profile.avatar}></Avatar>
            ) : (
              <Avatar>{profile.email?.[0]}</Avatar>
            )}
          </IconButton>
        </Tooltip>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={isDesktopOpen}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <MenuItem component={Link} to="/dashboard/profile">
            <ManageAccounts sx={{ mr: 2 }} /> จัดการบัญชี
          </MenuItem>
          <MenuItem component={Link} to="/dashboard/courses">
            <School sx={{ mr: 2 }} /> คอร์สของฉัน
          </MenuItem>
          {isAdmin && (
            <MenuItem component={Link} to="/admin">
              <SupervisorAccount sx={{ mr: 2 }} /> ส่วนจัดการหลังบ้าน
            </MenuItem>
          )}
          <Divider></Divider>
          <MenuItem onClick={logout}>
            <ExitToApp sx={{ mr: 2 }} /> ออกจากระบบ
          </MenuItem>
        </Menu>
      </>
    ) : (
      <ButtonLink
        variant="outlined"
        color="inherit"
        to="/auth/register"
        sx={{ ml: 'auto' }}
      >
        ลงทะเบียน
      </ButtonLink>
    );
  }, [
    anchorEl,
    handleDesktopMenuClick,
    handleClose,
    isAdmin,
    logout,
    isDesktopOpen,
    profile,
  ]);

  const mobileMenu = useMemo(() => {
    if (!profile)
      return (
        <>
          <Divider></Divider>
          <ListItemButton onClick={navigateTo('/auth/register')}>
            <ListItemIcon>
              <Login></Login>
            </ListItemIcon>
            <ListItemText primary="ลงทะเบียน"></ListItemText>
          </ListItemButton>
        </>
      );

    return (
      <>
        <Divider></Divider>
        <ListItemButton onClick={handleMobileMenuClick}>
          <ListItemIcon>
            {profile.avatar ? (
              <Avatar src={profile.avatar}></Avatar>
            ) : (
              <Avatar>{profile.email?.[0]}</Avatar>
            )}
          </ListItemIcon>
          <ListItemText primary={profile.name ?? profile.email} />
          {isMobileOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={isMobileOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <AutoHideListItemButton
              icon={<ManageAccounts></ManageAccounts>}
              title="จัดการบัญชี"
              path="/dashboard/profile"
              side="right"
            ></AutoHideListItemButton>
            <AutoHideListItemButton
              icon={<School></School>}
              title="คอร์สของฉัน"
              path="/dashboard/courses"
              side="right"
            ></AutoHideListItemButton>
            <AutoHideListItemButton
              icon={<SupervisorAccount></SupervisorAccount>}
              title="ส่วนจัดการหลังบ้าน"
              path="/admin"
              side="right"
            ></AutoHideListItemButton>
            <Divider></Divider>
            <AutoHideListItemButton
              icon={<ExitToApp></ExitToApp>}
              title="ออกจากระบบ"
              onClick={logout}
              side="right"
            ></AutoHideListItemButton>
          </List>
        </Collapse>
      </>
    );
  }, [handleMobileMenuClick, isMobileOpen, logout, navigateTo, profile]);

  useEffect(() => {
    if (matches) handleClose();
  }, [handleClose, matches]);

  if (!isProfileLoaded) {
    return <CircularProgress sx={{ ml: 'auto' }}></CircularProgress>;
  }

  return matches ? mobileMenu : desktopMenu;
};

export default UserMenu;
