import { ui as types } from '@codesass/types';

const config: types.Config = {
  sidebar: [
    {
      path: 'blog/articles/*',
      enableLeft: true,
      isLeftShown: true,
    },
  ],
  footer: [
    {
      path: 'blog/articles/*',
      shown: false,
    },
  ],
};

export default config;
