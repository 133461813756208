import { globalHistory } from '@reach/router';

import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useTheme } from '@mui/material/styles';

import { useMediaQuery } from '@mui/material';

import config, { defaultSidebarConfig } from 'modules/ui/config';

import { enableSidebar, showSidebar } from '../slice';

const findPathEntry = (pathname: string) => {
  const sidebarConfig = config.sidebar?.find(
    ({ path }) => path && new RegExp(path).test(pathname)
  );

  return {
    ...defaultSidebarConfig,
    ...sidebarConfig,
  };
};

const useHandleSidebar = (): void => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  const configSidebar = useCallback(
    (pathname: string) => {
      dispatch(
        enableSidebar({
          left: findPathEntry(pathname)?.enableLeft,
          right: true,
        })
      );

      if (matches) return;
      dispatch(
        showSidebar({
          left: findPathEntry(pathname)?.isLeftShown,
          right: false,
        })
      );
    },
    [dispatch, matches]
  );

  useEffect(() => {
    return globalHistory.listen(({ location: { pathname } }) => {
      configSidebar(pathname);
    });
  }, [configSidebar, dispatch]);

  useEffect(() => {
    configSidebar(globalHistory.location.pathname);
  }, [configSidebar]);
};

export default useHandleSidebar;
