import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { navigate } from 'gatsby';

import { auth as api } from '@codesass/api';
import { auth as types } from '@codesass/types';

import { UpdateProfileParams } from '@codesass/api/dist/modules/auth/updateProfile';

import { setIsLoading, showFlashMessage } from 'modules/ui/slice';

import { MESSAGES } from './constants';

type State = {
  profile: types.Profile | undefined;
  isProfileLoaded: boolean;
};

const initialState: State = {
  profile: undefined,
  isProfileLoaded: false,
};

export const createUser = createAsyncThunk<
  types.Profile | undefined,
  types.UserCredential
>('auth/createUser', async (userCredential, { dispatch, rejectWithValue }) => {
  try {
    const profile = await api.createUser(userCredential);

    navigate('/');
    dispatch(
      showFlashMessage({
        type: 'success',
        message: MESSAGES.CREATE_USER_SUCCESS,
      })
    );

    return profile;
  } catch (error) {
    if (!(error instanceof Error)) return rejectWithValue(error);

    const { message } = error;
    dispatch(showFlashMessage({ type: 'error', message }));

    return rejectWithValue(message);
  }
});

export const verifyEmail = createAsyncThunk<void, string>(
  'auth/verifyEmail',
  async (oobCode, { dispatch, rejectWithValue }) => {
    dispatch(setIsLoading(true));

    try {
      const currentUser = await api.getCurrentUser();
      if (!currentUser) {
        navigate('/auth/login', {
          state: {
            prev: `/auth/callback?mode=verifyEmail&oobCode=${oobCode}`,
          },
        });
        dispatch(
          showFlashMessage({
            type: 'warning',
            message: MESSAGES.LOGIN_BEFORE_VERIFYING_EMAIL,
          })
        );
        return;
      }

      await api.verifyEmail(oobCode);
      await api.reloadCurrentUser();
      dispatch(setIsLoading(false));
      navigate('/');
      dispatch(loadProfile());
      dispatch(
        showFlashMessage({
          type: 'success',
          message: MESSAGES.VERIFY_EMAIL_SUCCESS,
        })
      );
    } catch (error) {
      if (error instanceof api.VerifyEmailError) {
        dispatch(showFlashMessage({ type: 'error', message: error.message }));
        navigate('/');
        return rejectWithValue(error.message);
      }
    }
  }
);

export const login = createAsyncThunk<
  types.Profile | undefined,
  types.UserCredential & { prevPath?: string | undefined }
>(
  'auth/login',
  async ({ prevPath, ...userCredential }, { dispatch, rejectWithValue }) => {
    try {
      const profile = await api.login(userCredential);
      navigate(prevPath ?? '/', { replace: Boolean(prevPath) });

      if (!profile?.emailVerified) {
        dispatch(
          showFlashMessage({
            type: 'warning',
            message: MESSAGES.LOGIN_WITHOUT_EMAIL_VERIFICATION,
          })
        );
      }

      return profile;
    } catch (error) {
      if (error instanceof api.LoginError) {
        dispatch(showFlashMessage({ type: 'error', message: error.message }));

        return rejectWithValue(error.message);
      }
    }
  }
);

export const sendResetPassword = createAsyncThunk<void, types.UserCredential>(
  'auth/sendResetPassword',
  async ({ email }, { dispatch, rejectWithValue }) => {
    try {
      await api.sendResetPassword(email);
      navigate('/');

      dispatch(
        showFlashMessage({
          type: 'success',
          message:
            'ขั้นตอนการรีเซ็ตรหัสผ่านได้ถูกส่งไปยังอีเมล์ของคุณเรียบร้อยแล้ว',
        })
      );
    } catch (error) {
      if (error instanceof Error) {
        dispatch(
          showFlashMessage({
            type: 'error',
            message:
              'เกิดข้อผิดพลาด เป็นไปได้ว่าอีเมล์ของคุณไม่ถูกต้องหรือคุณยังไม่ได้ยืนยันอีเมล์',
          })
        );

        return rejectWithValue(error.message);
      }
    }
  }
);

export const resetPassword = createAsyncThunk<
  void,
  { oobCode: string; password: string }
>(
  'auth/resetPassword',
  async ({ oobCode, password }, { dispatch, rejectWithValue }) => {
    dispatch(setIsLoading(true));

    try {
      await api.resetPassword(oobCode, password);
      await api.reloadCurrentUser();
      dispatch(setIsLoading(false));
      navigate('/auth/login');
      dispatch(loadProfile());
      dispatch(
        showFlashMessage({
          type: 'success',
          message: MESSAGES.RESET_PASSWORD_SUCCESS,
        })
      );
    } catch (error) {
      if (error instanceof Error) {
        dispatch(showFlashMessage({ type: 'error', message: error.message }));
        navigate('/');
        return rejectWithValue(error.message);
      }
    }
  }
);

export const logout = createAsyncThunk('auth/logout', () => {
  return api.logout();
});

export const loadProfile = createAsyncThunk<types.Profile | undefined>(
  'auth/loadProfile',
  () => {
    try {
      return api.getProfile();
    } catch {
      return;
    }
  }
);

export const updateProfile = createAsyncThunk<void, UpdateProfileParams>(
  'auth/updateProfile',
  async (profile, { dispatch }) => {
    try {
      await api.updateProfile(profile);
      dispatch(
        showFlashMessage({
          type: 'success',
          message: 'การบันทึกข้อมูลบัญชีเสร็จสิ้น',
        })
      );
      dispatch(loadProfile());
    } catch (error) {
      if (error instanceof Error) {
        dispatch(
          showFlashMessage({
            type: 'error',
            message: error.message,
          })
        );
      }
    }
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(createUser.fulfilled, (state, action) => {
      state.profile = action.payload;
      state.isProfileLoaded = true;
    });

    builder.addCase(createUser.rejected, state => {
      state.profile = undefined;
      state.isProfileLoaded = true;
    });

    builder.addCase(login.fulfilled, (state, action) => {
      state.profile = action.payload;
      state.isProfileLoaded = true;
    });

    builder.addCase(login.rejected, state => {
      state.profile = undefined;
      state.isProfileLoaded = true;
    });

    builder.addCase(logout.fulfilled, state => {
      state.profile = undefined;
    });

    builder.addCase(loadProfile.fulfilled, (state, action) => {
      state.profile = action.payload;
      state.isProfileLoaded = true;
    });

    builder.addCase(loadProfile.rejected, state => {
      state.profile = undefined;
      state.isProfileLoaded = true;
    });
  },
});

export default authSlice.reducer;
