import LeftSidebar from './LeftSidebar';
import RightSidebar from './RightSidebar';

const Sidebar = () => {
  return (
    <>
      <LeftSidebar></LeftSidebar>
      <RightSidebar></RightSidebar>
    </>
  );
};

export default Sidebar;
