import React from 'react';

export const shouldUpdateScroll = () => {
  window.scrollTo(0, 0);

  return true;
};

export const onClientEntry = () => {
  if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line unicorn/prefer-module
    const whyDidYouRender = require('@welldone-software/why-did-you-render');
    whyDidYouRender(React);
  }
};

export { default as wrapRootElement } from './wrap-with-provider';
