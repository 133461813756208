import { forwardRef } from 'react';
import { Link as MuiLink, LinkProps } from '@mui/material';
import { Link as GatsbyLink } from 'gatsby';
import { LinkGetProps } from '@reach/router';

const isActive = ({
  location: { pathname },
  isPartiallyCurrent,
  href,
}: LinkGetProps) => {
  return isPartiallyCurrent && pathname !== '/' && href !== '/'
    ? { style: { borderBottom: '3px solid black' } }
    : {};
};

const Link = forwardRef<
  HTMLAnchorElement,
  LinkProps & { to?: string; showActive?: boolean }
>((props, ref) => {
  const { to, showActive = true, ...linkProps } = props;

  return to ? (
    <MuiLink
      ref={ref}
      component={GatsbyLink}
      to={to}
      color="inherit"
      getProps={showActive ? isActive : undefined}
      {...linkProps}
    />
  ) : (
    <MuiLink ref={ref} {...linkProps} />
  );
});

export default Link;
