export const MESSAGES = {
  CREATE_USER_SUCCESS:
    'บัญชีผู้ใช้งานได้ถูกสร้างเรียบร้อยแล้วกรุณายืนยันอีเมล หากยืนยันอีเมล์แล้วกรุณารีเฟรชหน้าเพจนี้',
  VERIFY_EMAIL_SUCCESS: 'อีเมลของคุณได้รับการยืนยันเป็นที่เรียบร้อย',
  RESET_PASSWORD_SUCCESS: 'รหัสผ่านของคุณได้รับการเปลี่ยนเรียบร้อย',
  LOGIN_BEFORE_VERIFYING_EMAIL:
    'กรุณาเข้าสู่ระบบก่อน เมื่อเสร็จสิ้นแล้วระบบจะยืนยันอีเมล์ให้กับคุณโดยอัตโนมัติ',
  LOGIN_WITHOUT_EMAIL_VERIFICATION:
    'บัญชีของคุณยังไม่ได้ทำการยืนยันอีเมล ฟังก์ชันบางอย่างอาจใช้งานไม่ได้ หากคุณไม่ได้รับอีเมล์โปรดกดเพื่อส่งการยืนยันอีเมล์อีกครั้งในเมนูจัดการบัญชี',
};
