import { useDispatch, useSelector } from 'react-redux';
import { createTeleporter } from 'react-teleporter';

import {
  Box,
  Drawer as MuiDrawer,
  Toolbar,
  useMediaQuery,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { useLocation } from '@reach/router';

import { useEffect } from 'react';

import { drawerWidth } from 'modules/ui/constants';
import { selectIsTopBarHide, selectSidebar } from 'modules/ui/selectors';
import { showSidebar } from 'modules/ui/slice';
import CourseToc from 'modules/courses/components/lessons/toc/components/Toc';

export const LeftSidebarRenderer = createTeleporter();

type DrawerProps = {
  isLeftShown: boolean;
};

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== 'isLeftShown',
})<DrawerProps>(({ isLeftShown, theme }) => ({
  flexShrink: 0,

  ...(isLeftShown && {
    width: drawerWidth.xs,
    transition: theme.transitions.create(['width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.up('md')]: {
      width: drawerWidth.md,
    },
  }),

  ...(!isLeftShown && {
    width: 0,
    transition: theme.transitions.create(['width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  }),

  '& .MuiDrawer-paper': {
    ...(isLeftShown && {
      width: drawerWidth.xs,
      marginLeft: 0,
      transition: theme.transitions.create(['margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      [theme.breakpoints.up('md')]: {
        width: drawerWidth.md,
      },
    }),

    ...(!isLeftShown && {
      width: drawerWidth.xs,
      marginLeft: `calc(-${drawerWidth.xs})`,
      transition: theme.transitions.create(['margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      [theme.breakpoints.up('md')]: {
        width: drawerWidth.md,
        marginRight: `calc(-${drawerWidth.md})`,
      },
    }),
  },
}));

const LeftSidebar = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { isLeftShown } = useSelector(selectSidebar);
  const isTopBarHide = useSelector(selectIsTopBarHide);
  const lessonPathRegExp =
    /courses\/(?<courseSlug>[\w-]+)\/((lessons\/[\w-]+)|overview)\/?$/;
  const lessonPathMatched = lessonPathRegExp.exec(pathname);
  const courseSlug = lessonPathMatched?.groups?.['courseSlug'];

  useEffect(() => {
    if (matches) dispatch(showSidebar({ left: false }));
  }, [dispatch, matches]);

  return (
    <Drawer variant="permanent" open={isLeftShown} isLeftShown={isLeftShown}>
      {!isTopBarHide && <Toolbar />}
      <Box sx={{ overflow: 'auto' }}>
        {courseSlug ? (
          <CourseToc slug={courseSlug}></CourseToc>
        ) : (
          <LeftSidebarRenderer.Target />
        )}
      </Box>
    </Drawer>
  );
};

LeftSidebar.RendererSource = LeftSidebarRenderer.Source;

export default LeftSidebar;
