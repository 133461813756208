import { ui as types } from '@codesass/types';

import LinkItem from './LinkItem';
import NestedLinkItems from './NestedLinkItems';

type TableOfContentsItemProps = types.TableOfContentsItem;

const TableOfContentsItem = ({
  title,
  url,
  items,
}: TableOfContentsItemProps) => {
  return items?.length ? (
    <NestedLinkItems title={title} url={url} items={items} />
  ) : (
    <LinkItem title={title} url={url} />
  );
};

export default TableOfContentsItem;
