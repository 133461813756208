import { ElementType, FC, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { useDispatch } from 'react-redux';

import { setCurrentTocId } from 'modules/ui/slice';

type AnchorProps = {
  tag: ElementType;
  level: 'heading' | 'subheading';
  id: string;
};

const Anchor: FC<AnchorProps> = ({ tag: Tag, id, children }) => {
  const dispatch = useDispatch();
  const { ref, entry, inView } = useInView({
    rootMargin: '0px 0px -90% 0px',
  });

  useEffect(() => {
    if (entry?.isIntersecting) dispatch(setCurrentTocId(id));
  }, [id, entry?.isIntersecting, dispatch, inView]);

  return (
    <Tag id={id} ref={ref}>
      {children}
    </Tag>
  );
};

export default Anchor;
