import { ui as types } from '@codesass/types';

import TableOfContentsItems from './TableOfContentsItems';

type TableOfContentsProps = {
  toc: types.TableOfContentsItem[];
};

const TableOfContents = ({ toc }: TableOfContentsProps) => {
  return <TableOfContentsItems items={toc}></TableOfContentsItems>;
};

export default TableOfContents;
