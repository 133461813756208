import { ui as types } from '@codesass/types';

const config: types.Config = {
  sidebar: [
    {
      path: 'courses/[\\w-]+/lessons/*',
      enableLeft: true,
      isLeftShown: true,
    },
    {
      path: 'courses/[\\w-]+/overview',
      enableLeft: true,
      isLeftShown: true,
    },
  ],
  footer: [
    {
      path: 'courses/[\\w-]+/lessons/*',
      shown: false,
    },
    {
      path: 'courses/[\\w-]+/overview',
      shown: false,
    },
  ],
};

export default config;
