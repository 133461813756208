import { Fragment, ReactElement, ReactNode } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { defineCustomElements as deckDeckGoHighlightElement } from '@deckdeckgo/highlight-code/dist/loader';

import Layout from 'modules/ui/components/layouts/Normal';
import theme from 'modules/ui/theme';

type TopLayoutProps = {
  children: ReactNode;
};

deckDeckGoHighlightElement();

function TopLayout(props: TopLayoutProps): ReactElement {
  return (
    <Fragment>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Layout>{props.children}</Layout>
      </ThemeProvider>
    </Fragment>
  );
}

export default TopLayout;

export const Head = () => {
  return (
    <>
      <meta name="viewport" content="initial-scale=1, width=device-width" />
      <link
        href="https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap"
        rel="stylesheet"
      />
    </>
  );
};
