export const drawerWidth = {
  xs: '100%',
  md: 350,
};

export const appbarHeight = {
  xs: 56,
  md: 64,
};

export const footerHeight = {
  xs: 228,
  md: 96,
};
