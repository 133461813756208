import { List } from '@mui/material';

import { ui as types } from '@codesass/types';

import TableOfContentsItem from './TableOfContentsItem';

type TableOfContentsItemsProps = types.TableOfContents;

const TableOfContentsItems = ({ items }: TableOfContentsItemsProps) => {
  return (
    <List>
      {items.map(item => (
        <TableOfContentsItem key={item.title} {...item}></TableOfContentsItem>
      ))}
    </List>
  );
};

export default TableOfContentsItems;
