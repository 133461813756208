import { MDXProvider } from '@mdx-js/react';

import { FC } from 'react';

import { MDXRenderer } from 'gatsby-plugin-mdx';

import { styled } from '@mui/material/styles';

import mdxShortcodes from './shortcodes';

type RendererProps = {
  children: string;
};

const Wrapper = styled('div')(({ theme }) => ({
  '& a': {
    color: theme.palette.primary.main,
    textDecoration: 'none',
  },

  '& a.anchor': {
    marginLeft: theme.spacing(1),
  },

  '& code': {
    backgroundColor: theme.palette.grey[300],
    padding: theme.spacing(0.5),
  },

  '& h2': {
    ...theme.typography.h5,

    borderLeft: `5px solid ${theme.palette.secondary.main}`,
    paddingLeft: theme.spacing(1),
  },

  '& h3': {
    ...theme.typography.h6,

    borderLeft: `5px solid ${theme.palette.secondary.main}`,
    paddingLeft: theme.spacing(1),
  },

  '& blockquote': {
    backgroundColor: theme.palette.grey[100],
    margin: 0,
    padding: theme.spacing(2),
    color: theme.palette.grey[800],

    '& p': {
      margin: 0,
    },
  },

  '& p,pre': { overflow: 'auto' },
  '& .MuiTypography-root': { overflow: 'auto' },
}));

const Renderer: FC<RendererProps> = ({ children }) => {
  return (
    <Wrapper>
      <MDXProvider components={mdxShortcodes}>
        <MDXRenderer>{children}</MDXRenderer>
      </MDXProvider>
    </Wrapper>
  );
};

export default Renderer;
