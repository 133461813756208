exports.components = {
  "component---src-modules-blog-templates-article-tsx": () => import("./../../../src/modules/blog/templates/article.tsx" /* webpackChunkName: "component---src-modules-blog-templates-article-tsx" */),
  "component---src-modules-blog-templates-blog-tsx": () => import("./../../../src/modules/blog/templates/blog.tsx" /* webpackChunkName: "component---src-modules-blog-templates-blog-tsx" */),
  "component---src-modules-courses-templates-course-tsx": () => import("./../../../src/modules/courses/templates/course.tsx" /* webpackChunkName: "component---src-modules-courses-templates-course-tsx" */),
  "component---src-modules-courses-templates-courses-tsx": () => import("./../../../src/modules/courses/templates/courses.tsx" /* webpackChunkName: "component---src-modules-courses-templates-courses-tsx" */),
  "component---src-modules-courses-templates-lesson-tsx": () => import("./../../../src/modules/courses/templates/lesson.tsx" /* webpackChunkName: "component---src-modules-courses-templates-lesson-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-admin-courses-cert-exams-tsx": () => import("./../../../src/pages/admin/courses/cert-exams.tsx" /* webpackChunkName: "component---src-pages-admin-courses-cert-exams-tsx" */),
  "component---src-pages-admin-courses-index-tsx": () => import("./../../../src/pages/admin/courses/index.tsx" /* webpackChunkName: "component---src-pages-admin-courses-index-tsx" */),
  "component---src-pages-admin-courses-registrations-tsx": () => import("./../../../src/pages/admin/courses/registrations.tsx" /* webpackChunkName: "component---src-pages-admin-courses-registrations-tsx" */),
  "component---src-pages-admin-index-tsx": () => import("./../../../src/pages/admin/index.tsx" /* webpackChunkName: "component---src-pages-admin-index-tsx" */),
  "component---src-pages-auth-callback-tsx": () => import("./../../../src/pages/auth/callback.tsx" /* webpackChunkName: "component---src-pages-auth-callback-tsx" */),
  "component---src-pages-auth-login-tsx": () => import("./../../../src/pages/auth/login.tsx" /* webpackChunkName: "component---src-pages-auth-login-tsx" */),
  "component---src-pages-auth-register-tsx": () => import("./../../../src/pages/auth/register.tsx" /* webpackChunkName: "component---src-pages-auth-register-tsx" */),
  "component---src-pages-auth-reset-password-tsx": () => import("./../../../src/pages/auth/reset-password.tsx" /* webpackChunkName: "component---src-pages-auth-reset-password-tsx" */),
  "component---src-pages-auth-send-reset-password-tsx": () => import("./../../../src/pages/auth/send-reset-password.tsx" /* webpackChunkName: "component---src-pages-auth-send-reset-password-tsx" */),
  "component---src-pages-certs-tsx": () => import("./../../../src/pages/certs.tsx" /* webpackChunkName: "component---src-pages-certs-tsx" */),
  "component---src-pages-courses-courses-yaml-fields-slug-overview-tsx": () => import("./../../../src/pages/courses/{CoursesYaml.fields__slug}/overview.tsx" /* webpackChunkName: "component---src-pages-courses-courses-yaml-fields-slug-overview-tsx" */),
  "component---src-pages-courses-courses-yaml-fields-slug-register-tsx": () => import("./../../../src/pages/courses/{CoursesYaml.fields__slug}/register.tsx" /* webpackChunkName: "component---src-pages-courses-courses-yaml-fields-slug-register-tsx" */),
  "component---src-pages-dashboard-courses-tsx": () => import("./../../../src/pages/dashboard/courses.tsx" /* webpackChunkName: "component---src-pages-dashboard-courses-tsx" */),
  "component---src-pages-dashboard-index-tsx": () => import("./../../../src/pages/dashboard/index.tsx" /* webpackChunkName: "component---src-pages-dashboard-index-tsx" */),
  "component---src-pages-dashboard-profile-tsx": () => import("./../../../src/pages/dashboard/profile.tsx" /* webpackChunkName: "component---src-pages-dashboard-profile-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-policy-tsx": () => import("./../../../src/pages/policy.tsx" /* webpackChunkName: "component---src-pages-policy-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */)
}

