import { useDispatch, useSelector } from 'react-redux';
import { createTeleporter } from 'react-teleporter';

import {
  Box,
  Drawer as MuiDrawer,
  Toolbar,
  useMediaQuery,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

import { useEffect } from 'react';

import { drawerWidth } from 'modules/ui/constants';
import { selectIsTopBarHide, selectSidebar } from 'modules/ui/selectors';
import { showSidebar } from 'modules/ui/slice';

export const RightSidebarRenderer = createTeleporter();

type DrawerProps = {
  isRightShown: boolean;
};

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== 'isRightShown',
})<DrawerProps>(({ isRightShown, theme }) => ({
  flexShrink: 0,

  ...(isRightShown && {
    width: drawerWidth.xs,
    transition: theme.transitions.create(['width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.up('md')]: {
      width: drawerWidth.md,
    },
  }),

  ...(!isRightShown && {
    width: 0,
    transition: theme.transitions.create(['width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  }),

  '& .MuiDrawer-paper': {
    ...(isRightShown && {
      width: drawerWidth.xs,
      marginRight: 0,
      transition: theme.transitions.create(['margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      [theme.breakpoints.up('md')]: {
        width: drawerWidth.md,
      },
    }),

    ...(!isRightShown && {
      width: drawerWidth.xs,
      marginRight: `calc(-${drawerWidth.xs})`,
      transition: theme.transitions.create(['margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      [theme.breakpoints.up('md')]: {
        width: drawerWidth.md,
        marginRight: `calc(-${drawerWidth.md})`,
      },
    }),
  },
}));

const RightSidebar = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const dispatch = useDispatch();
  const { isRightShown } = useSelector(selectSidebar);
  const isTopBarHide = useSelector(selectIsTopBarHide);

  useEffect(() => {
    if (matches) dispatch(showSidebar({ right: false }));
  }, [dispatch, matches]);

  return (
    <Drawer
      variant="permanent"
      open={false}
      isRightShown={isRightShown}
      anchor="right"
    >
      {!isTopBarHide && <Toolbar />}
      <Box sx={{ overflow: 'auto' }}>
        <RightSidebarRenderer.Target />
      </Box>
    </Drawer>
  );
};

RightSidebar.RendererSource = RightSidebarRenderer.Source;

export default RightSidebar;
