import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ListItem, ListItemText, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { ui as types } from '@codesass/types';

import { selectCurrentTocId } from 'modules/ui/selectors';

import { setCurrentTocId, showSidebar } from 'modules/ui/slice';

import AutoHideListItemButton from '../../AutoHideListItemButton';

type LinkItemProps = Omit<types.TableOfContentsItem, 'items'>;

const LinkItem = ({ title, url }: LinkItemProps) => {
  const dispatch = useDispatch();
  const currentTocId = useSelector(selectCurrentTocId);
  const [isActive, setIsActive] = useState(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    setIsActive(currentTocId === url);
  }, [currentTocId, url]);

  const handleClick = useCallback(() => {
    window.location.hash = url;

    dispatch(setCurrentTocId(url));
    if (matches) dispatch(showSidebar({ left: false }));
  }, [dispatch, matches, url]);

  return (
    <AutoHideListItemButton
      title={title}
      onClick={handleClick}
      side="left"
      selected={isActive}
    ></AutoHideListItemButton>
  );
};

export default LinkItem;
