import { mergeWith } from 'lodash';

import { ui as types } from '@codesass/types';

import blog from 'modules/blog/config/ui';
import admin from 'modules/admin/config/ui';
import courses from 'modules/courses/config/ui';
import dashboard from 'modules/dashboard/config/ui';

export const defaultSidebarConfig = {
  enableLeft: false,
  enableRight: true,
  isRightShown: false,
  isLeftShown: false,
};

export const defaultFooterConfig = {
  shown: true,
};

const uiConfig: types.Config = mergeWith(
  {},
  blog,
  admin,
  courses,
  dashboard,
  (a, b) => {
    if (Array.isArray(a) && Array.isArray(b)) {
      return [...b, ...a];
    }
  }
);

export default uiConfig;
