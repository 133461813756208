import { courses as types } from '@codesass/types';
import {
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
} from '@mui/material';

import TocLessonList from './TocLessonList';

type TocUnitProps = types.Toc[number] & {
  unitIndex: number;
  courseSlug: types.Course['slug'];
};

const TocUnit = ({
  unitIndex,
  slug,
  title,
  desc,
  lessons,
  courseSlug,
}: TocUnitProps) => {
  return (
    <List key={slug}>
      <ListItem sx={{ backgroundColor: theme => theme.palette.grey[100] }}>
        <ListItemAvatar>
          <Avatar>{unitIndex}</Avatar>
        </ListItemAvatar>
        <ListItemText primary={title} secondary={desc}></ListItemText>
      </ListItem>
      <List>
        <TocLessonList
          courseSlug={courseSlug}
          lessons={lessons}
        ></TocLessonList>
      </List>
    </List>
  );
};

export default TocUnit;
