import Cookies from 'js-cookie';
import { Button, Typography, Link, Box } from '@mui/material';
import { Link as GatsbyLink } from 'gatsby';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';

import { setAcceptedConsent, showConsent } from '../slice';
import { selectAcceptedConsent, selectIsConsentShown } from '../selectors';
import { storeConsent } from '../helpers/consent';

const ConsentNotification = () => {
  const dispatch = useDispatch();
  const isConsentShown = useSelector(selectIsConsentShown);
  const acceptedConsent = useSelector(selectAcceptedConsent);

  const openDialog = useCallback(() => {
    dispatch(showConsent(true));
  }, [dispatch]);

  const acceptAllConsents = useCallback(() => {
    storeConsent({ analysis: true, marketing: true });
    dispatch(setAcceptedConsent(true));
  }, [dispatch]);

  useEffect(() => {
    dispatch(setAcceptedConsent(Cookies.get('accept-consent') === 'true'));
  }, [dispatch]);

  if (acceptedConsent || isConsentShown) return null;
  return (
    <Box
      component="article"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        position: 'fixed',
        zIndex: theme => theme.zIndex.drawer + 2,
        color: 'grey.800',
        left: 0,
        bottom: 0,
        width: '100%',
      }}
    >
      <Box
        maxWidth={960}
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: { xs: 'column', md: 'row' },
          p: 2,
          m: 2,
          border: '1px solid rgb(232, 232, 232)',
          borderRadius: '10px',
          color: 'grey.800',
          bgcolor: 'white',
        }}
      >
        <Box flex={1} mr={2}>
          <Typography mb={2}>เว็บไซต์นี้ใช้คุกกี้และมีการเก็บข้อมูล</Typography>
          <Typography fontSize="0.85rem">
            เรามีการจัดเก็บข้อมูลและใช้คุกกี้สำหรับระบุตัวตนผู้ใช้งานพร้อมเพิ่มประสิทธิภาพและปรับปรุงประสบการณ์การใช้งานเว็บไซต์ของคุณให้ดียิ่งขึ้น
            คุณสามารถตั้งค่าการยินยอมการใช้งานข้อมูลจากลิงก์การตั้งค่าหรือ{' '}
            <Link component={GatsbyLink} to="/privacy" color="inherit">
              อ่านนโยบายข้อมูลส่วนบุคคล
            </Link>
          </Typography>
        </Box>
        <Box
          mt={{ xs: 2, md: 0 }}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width={{ sx: '100%', md: 'auto' }}
        >
          <Link
            color="inherit"
            mr={2}
            onClick={openDialog}
            sx={{ cursor: 'pointer', textDecoration: 'none' }}
          >
            การตั้งค่า
          </Link>
          <Button variant="contained" size="small" onClick={acceptAllConsents}>
            ยอมรับทั้งหมด
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ConsentNotification;
