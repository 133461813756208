import { MDXProviderComponentsProp } from '@mdx-js/react';

import { ComponentProps, ReactElement } from 'react';

import Anchor from './Anchor';
import CodeHighlighter, { CodeHighlighterProps } from './CodeHighlighter';

type HeadingProps = Omit<ComponentProps<typeof Anchor>, 'tag'>;

const mdxShortCodes: MDXProviderComponentsProp = {
  h2: (props: HeadingProps): ReactElement => <Anchor tag="h2" {...props} />,
  h3: (props: HeadingProps): ReactElement => <Anchor tag="h3" {...props} />,
  pre: (props: CodeHighlighterProps): ReactElement => (
    <CodeHighlighter {...props} />
  ),
};

export default mdxShortCodes;
