import { FC, ReactElement, useEffect } from 'react';

import { Slide, useScrollTrigger } from '@mui/material';

type HideOnScrollProperties = {
  children: ReactElement;
  direction?: 'up' | 'down';
  when?: 'up' | 'down';
  threshold?: number;
  visible?: boolean | null;
  onTrigger?: (trigger: boolean) => void;
};

const HideOnScroll: FC<HideOnScrollProperties> = ({
  direction = 'down',
  when = 'up',
  threshold,
  visible = true,
  onTrigger,
  children,
}) => {
  const trigger = useScrollTrigger({ threshold: threshold ?? 0 });
  let inProp = false;

  if (visible) inProp = when === 'down' ? trigger : !trigger;

  useEffect(() => {
    onTrigger?.(inProp);
  }, [inProp, onTrigger]);

  return (
    <Slide appear={false} direction={direction} in={inProp}>
      {children}
    </Slide>
  );
};

export default HideOnScroll;
