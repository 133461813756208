import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  List,
  ListItem,
  ListItemText,
  Switch,
  Link,
} from '@mui/material';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  ConsentDialogSetting,
  loadConsent,
  storeConsent,
} from '../helpers/consent';

import { selectIsConsentShown } from '../selectors';
import { setAcceptedConsent, showConsent } from '../slice';

const ConsentDialog = () => {
  const dispatch = useDispatch();
  const isConsentShown = useSelector(selectIsConsentShown);
  const [consentSetting, setConsentSetting] = useState<ConsentDialogSetting>(
    loadConsent()
  );

  const close = useCallback(() => {
    dispatch(showConsent(false));
  }, [dispatch]);

  const setConsent = useCallback(
    (key: keyof ConsentDialogSetting) => (e: ChangeEvent<HTMLInputElement>) => {
      const setting = { ...consentSetting, [key]: e.target.checked };

      setConsentSetting(setting);
    },
    [consentSetting]
  );

  const acceptMyConsents = useCallback(() => {
    storeConsent(consentSetting);
    dispatch(setAcceptedConsent(true));
    close();
  }, [close, consentSetting, dispatch]);

  const acceptAllConsents = useCallback(() => {
    storeConsent({ analysis: true, marketing: true });
    dispatch(setAcceptedConsent(true));
    close();
  }, [close, dispatch]);

  useEffect(() => {
    if (isConsentShown) setConsentSetting(loadConsent());
  }, [isConsentShown]);

  return (
    <Dialog
      open={isConsentShown}
      onClose={close}
      aria-labelledby="consent-dialog-title"
      aria-describedby="consent-dialog-description"
    >
      <DialogTitle id="consent-dialog-title" textAlign="center">
        การตั้งค่าความเป็นส่วนตัว
      </DialogTitle>
      <DialogContent>
        <List
          sx={{ width: '100%', maxWidth: 960, bgcolor: 'background.paper' }}
        >
          <ListItem>
            <ListItemText
              primary="การจัดเก็บข้อมูลที่จำเป็น"
              primaryTypographyProps={{ color: 'black' }}
              secondary={
                <Typography variant="body2" mt={2} color="grey.600">
                  เพื่อให้เว็บไซต์ยังคงตอบสนองต่อคุณได้อย่างถูกต้อง
                  การเก็บข้อมูลส่วนนี้จึงจำเป็นและจะเปิดใช้งานเสมอโดยไม่สามารถปิดการใช้งานได้
                  ตัวอย่างของข้อมูลที่จัดเก็บ เช่น
                  ข้อมูลตัวแทนที่สื่อถึงบัญชีผู้ใช้งานของคุณ (User ID) เป็นต้น
                </Typography>
              }
            />
            <Switch edge="end" checked={true} />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="ข้อมูลส่วนวิเคราะห์"
              primaryTypographyProps={{ color: 'black' }}
              secondary={
                <Typography variant="body2" mt={2} color="grey.600">
                  เพื่อให้เว็บไซต์เข้าใจรูปแบบการใช้งานของผู้เข้าชมอันจะช่วยให้เราสามารถปรับปรุงประสิทธิภาพการใช้งานได้อย่างดีขึ้น
                  การเก็บข้อมูลในส่วนนี้จึงจำเป็นต่อการวิเคราะห์พฤติกรรมผู้ใช้งาน
                </Typography>
              }
            />
            <Switch
              edge="end"
              onChange={setConsent('analysis')}
              checked={consentSetting.analysis}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="ข้อมูลส่วนการตลาด"
              primaryTypographyProps={{ color: 'black' }}
              secondary={
                <Typography variant="body2" mt={2} color="grey.600">
                  ข้อมูลส่วนนี้ถูกจัดเก็บในรูปแบบของคุกกี้เพื่อใช้ติดตามพฤติกรรมผู้เข้าชมอันนำไปสู่การแสดงโฆษณาที่เหมาะสมต่อผู้ใช้งานแต่ละราย
                </Typography>
              }
            />
            <Switch
              edge="end"
              onChange={setConsent('marketing')}
              checked={consentSetting.marketing}
            />
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions>
        <Link
          color="inherit"
          sx={{ cursor: 'pointer', textDecoration: 'none', mr: 2 }}
          onClick={acceptMyConsents}
        >
          ยอมรับตัวเลือกของฉัน
        </Link>
        <Button variant="contained" onClick={acceptAllConsents}>
          ยอมรับทั้งหมด
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConsentDialog;
