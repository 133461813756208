import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { courses as types } from '@codesass/types';
import { courses } from '@codesass/api';

type CoursesState = {
  currentToc?: types.Toc | undefined;
  viewStatuses?: types.LessonViewStatuses | undefined;
};

const initialState: CoursesState = {
  currentToc: undefined,
  viewStatuses: undefined,
};

export const loadToc = createAsyncThunk<types.Toc, types.Course['slug']>(
  'courses/loadToc',
  async (slug, { rejectWithValue }) => {
    try {
      const toc = await courses.getToc(slug);

      return toc;
    } catch (error) {
      const message = error instanceof Error ? error.message : error;

      return rejectWithValue(message);
    }
  }
);

export const loadLessonViewStatuses = createAsyncThunk<
  types.LessonViewStatuses | undefined,
  types.Course['slug']
>('courses/loadLessonViewStatuses', async (slug, { rejectWithValue }) => {
  try {
    const viewStatuseses = await courses.getLessonViewStatuses(slug);

    return viewStatuseses;
  } catch (error) {
    const message = error instanceof Error ? error.message : error;

    return rejectWithValue(message);
  }
});

const coursesSlice = createSlice({
  name: 'courses',
  initialState,
  reducers: {
    setLessonViewStatus(
      state,
      action: PayloadAction<types.LessonViewStatusPayload>
    ) {
      const { slug, viewStatus } = action.payload;
      const overridedViewStatus = {
        ...viewStatus,
        status: courses.StatusMapping[viewStatus.status],
      };

      if (state.viewStatuses === undefined) {
        state.viewStatuses = { [slug]: overridedViewStatus };
      } else {
        state.viewStatuses[slug] = overridedViewStatus;
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(loadToc.fulfilled, (state, action) => {
      state.currentToc = action.payload;
    });

    builder.addCase(loadToc.rejected, state => {
      state.currentToc = undefined;
    });

    builder.addCase(loadLessonViewStatuses.fulfilled, (state, action) => {
      state.viewStatuses = action.payload;
    });

    builder.addCase(loadLessonViewStatuses.rejected, state => {
      state.viewStatuses = undefined;
    });
  },
});

export const { setLessonViewStatus } = coursesSlice.actions;

export default coursesSlice.reducer;
