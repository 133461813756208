import { courses as types } from '@codesass/types';

import TocUnit from './TocUnit';

type TocUnitListProps = {
  courseSlug: types.Course['slug'];
  units: types.Toc;
};

const TocUnitList = ({ courseSlug, units }: TocUnitListProps) => {
  return (
    <>
      {Object.entries(units || []).map(([index, unit]) => (
        <TocUnit
          key={unit.slug}
          {...unit}
          unitIndex={Number.parseInt(index) + 1}
          courseSlug={courseSlug}
        ></TocUnit>
      ))}
    </>
  );
};

export default TocUnitList;
